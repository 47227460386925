<template>
  <section class="intro">
    <div class="intro__grid">
      <div class="intro__icon">
        <img
          src="../assets/icons/intro-wave.svg"
          alt=""
          style="margin-top: 60vh"
        />
      </div>
      <div class="intro__content">
        <vue-markdown class="intro__text">{{ content }}</vue-markdown>
        <button class="btn" @click="$emit('incrementSlide')">
          Jetzt starten
        </button>
        <p class="intro__disclaimer">
          Character Strengths Rating Form (Texte:&nbsp;VIA&#x2011;Youth)<br />
          &copy; W. Ruch und Team, Psychologisches Institut der Universität
          Zürich
          <br />
          Bildmaterial zugefügt
        </p>
      </div>
      <div class="intro__icon">
        <img
          src="../assets/icons/intro-zickzack.svg"
          alt=""
          style="margin-top: 10vh"
        />
      </div>
    </div>
    <!-- <Footer /> -->
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "Intro",
  props: {
    content: String
  },
  components: {
    VueMarkdown
  }
};
</script>

<style lang="scss">
@import "../scss/look.scss";

.intro {
  .intro__grid {
    .intro__content {
      min-height: 90vh;

      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 5%;

      .intro__text {
        h1 {
          margin-bottom: 2rem;
          margin-top: 2rem;
        }
        p {
          margin-bottom: 3rem;
        }
      }

      .btn {
        width: auto;
      }
    }
    .intro__icon {
      display: none;
      img {
        width: 100%;
      }
    }
    .intro__disclaimer {
      margin-top: 3rem;
      font-size: 0.7rem;
    }
  }
}

@media (min-width: $md) {
  .intro {
    min-height: 90vh;
    grid-template-rows: 1fr;

    .intro__grid {
      display: grid;
      grid-template-columns: 20% 60% 20%;

      .intro__content {
        .btn {
          width: auto;
        }
      }
      .intro__icon {
        display: block;
      }
    }
  }
}
@media (min-width: $xxl) {
  .intro {
    .intro__grid {
      display: grid;
      grid-template-columns: 30% 40% 30%;
    }
  }
}
</style>
