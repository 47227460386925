<template>
  <section
    class="test"
    :class="{
      'quiz--blue': activeQuestion.color === 'blau',
      'quiz--red': activeQuestion.color === 'rot',
      'quiz--orange': activeQuestion.color === 'orange',
      'quiz--purple': activeQuestion.color === 'lila',
      'quiz--yellow': activeQuestion.color === 'gelb',
      'quiz--green': activeQuestion.color === 'grün',
    }"
  >
    <div class="border-wrapper">
      <div class="border">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 226 921"
          preserveAspectRatio="xMinYMin"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M7,133.5v654A126.51,126.51,0,0,0,133.5,914H226v7H133.5A133.5,133.5,0,0,1,0,787.5v-654A133.5,133.5,0,0,1,133.5,0H226V7H133.5A126.51,126.51,0,0,0,7,133.5Z"
              />
            </g>
          </g>
        </svg>
        <svg
          id="border-width"
          xmlns="http://www.w3.org/2000/svg"
          width="627"
          height="921"
          viewBox="0 0 627 921"
          preserveAspectRatio="none"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <rect class="cls-1" y="914" width="627" height="7" />
              <rect class="cls-1" width="627" height="7" />
            </g>
          </g>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 226 921"
          preserveAspectRatio="xMinYMin"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M191.35,876.56A133.66,133.66,0,0,1,91.9,921H0v-7H91.9A126.5,126.5,0,0,0,217.64,773.61l-72.24-654A126.36,126.36,0,0,0,19.66,7H0V0H19.66A133.34,133.34,0,0,1,152.35,118.84l72.25,654A133.67,133.67,0,0,1,191.35,876.56Z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div class="mobile-header">
      <div class="mobile-header__content">
        <h2 v-html="activeQuestion.headline"></h2>
      </div>
    </div>
    <div class="quiz">
      <div class="quiz__content_wrapper" id="quiz__content_wrapper">
        <div class="quiz__content">
          <div class="quiz__text">
            <transition name="fade" mode="out-in">
              <span :key="activeQuestion.id">
                <h2 v-html="activeQuestion.headline"></h2>
                <p>{{ activeQuestion.description }}</p>
              </span>
            </transition>

            <div class="quiz__back" @click="$emit('previousSlide')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43.331"
                height="31.584"
                viewBox="0 0 43.331 31.584"
              >
                <g transform="translate(57.749 30.169) rotate(180)">
                  <path
                    d="M11949.363,5453.063h-39.777v-4h39.777Z"
                    transform="translate(-11895.168 -5436.685)"
                  />
                  <path
                    d="M1.414,15.792l-2.828-2.828L12.964-1.414a2,2,0,0,1,2.828,0L30.17,12.963l-2.828,2.828L14.378,2.828Z"
                    transform="translate(55.749 0) rotate(90)"
                  />
                </g>
              </svg>
              <p>zurück</p>
            </div>
          </div>
          <Skala @clicked="nextSlide($event)" :active="activeQuestion.answer" class="quiz__skala" />
        </div>
      </div>
    </div>
    <div class="mobile-header">
      <div class="mobile-header__content">
        <div class="quiz__back" @click="$emit('previousSlide')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="43.331"
            height="31.584"
            viewBox="0 0 43.331 31.584"
          >
            <g transform="translate(57.749 30.169) rotate(180)">
              <path
                d="M11949.363,5453.063h-39.777v-4h39.777Z"
                transform="translate(-11895.168 -5436.685)"
              />
              <path
                d="M1.414,15.792l-2.828-2.828L12.964-1.414a2,2,0,0,1,2.828,0L30.17,12.963l-2.828,2.828L14.378,2.828Z"
                transform="translate(55.749 0) rotate(90)"
              />
            </g>
          </svg>
          <p>zurück</p>
        </div>
      </div>
    </div>

    <div class="image">
      <!-- <transition name="fade" mode="out-in"> -->
      <img :src="basePath + activeQuestion.image.url" alt="" :key="activeQuestion.id" />
      <!-- </transition> -->
    </div>
  </section>
</template>

<script>
import VueMarkdown from 'vue-markdown';
import Skala from '@/components/Skala.vue';
import Footer from '@/components/Footer.vue';
import Code from '@/components/Code.vue';

export default {
  name: 'Test',
  components: {
    Skala,
    Footer,
    Code,
    VueMarkdown,
  },
  props: {
    activeQuestion: Object,
    slide: Number,
    api: String,
  },
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_PATH,
    };
  },
  methods: {
    nextSlide(x) {
      this.$emit('nextSlide', x);
    },
  },
  mounted() {
    console.log(process.env.VUE_APP_BASE_PATH);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../scss/look.scss';

.border-wrapper {
  position: absolute;
  height: 90vh;
  justify-content: flex-start;
  align-items: center;
  display: none;

  @media (min-width: $lg) {
    display: flex;
  }
  @media (max-height: $md) {
    display: none;
  }

  .border {
    display: grid;
    grid-template-columns: min-content auto min-content;
    // justify-content: flex-start;
    height: 80vh;
    max-height: 800px;
    width: 70vw;

    // grid-template-columns: min-content 30vw min-content;
    margin-left: 2vw;

    @media (min-width: $xl) {
      // grid-template-columns: min-content 35vw min-content;
      width: 900px;
    }

    @media (min-width: $xxxl) {
      // grid-template-columns: min-content 520px min-content;
      width: 960px;
    }

    svg {
      height: inherit;
      max-height: 800px;
      &:nth-child(0) {
        grid-column: 0;
      }
      &:nth-child(2) {
        grid-column: 2;
      }
      &:nth-child(3) {
        grid-column: 3;
      }
    }

    #border-width {
      width: 100%;
    }
  }
}

.test {
  min-height: 90vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

@media (min-width: $md) {
  .test {
    height: auto;
    width: 100vw;
    display: grid;
    grid-template-rows: 4fr 5fr;
  }
}
@media (min-width: $lg) {
  .test {
    min-height: 90vh;
    max-width: $xxxl;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 1fr;
    margin: auto;
  }
}

.mobile-header {
  display: flex;
  justify-content: center;
  .mobile-header__content {
    width: 100%;
    grid-row: 1;
    padding: 0 2rem;
    max-width: 540px;
    overflow-wrap: break-word;

    h2 {
      margin-top: 3rem;
      margin-bottom: 0;

      @media (max-width: $sm) {
        word-break: break-word;
      }
    }
    .quiz__back {
      cursor: pointer;
      display: flex;
      align-items: center;
      // margin-bottom: 3rem;
      margin-top: 3rem;

      p {
        margin-left: 1rem;
      }
    }
  }

  @media (min-width: $md) {
    display: none;
  }
}

.quiz {
  // grid-column: 1;
  // grid-row: 1;

  .quiz__content_wrapper {
    .quiz__content {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding: 0 2rem;
      @media (max-width: $md) {
        align-items: center;
      }
      .quiz__text {
        // padding: 0 1rem;
        margin-bottom: 2rem;
        max-width: 540px;

        p {
          font-size: 1.2rem;
        }

        .quiz__back,
        h1,
        h2 {
          display: none;
        }
      }

      .quiz__skala {
        height: auto;
      }
    }
  }
}
@media (min-width: $md) {
  .quiz {
    position: relative;
    display: flex;

    .quiz__content_wrapper {
      position: absolute;
      display: flex;

      .quiz__content {
        display: grid;
        width: 100vw;
        padding: 0 8%;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr auto;
        // padding: 10%;
        height: auto;
        .quiz__text {
          display: flex;
          flex-direction: column;
          padding-right: 10%;
          margin-bottom: 0;

          .quiz__back {
            margin-top: auto;
            cursor: pointer;
            display: flex;
            align-items: center;

            p {
              margin-left: 1rem;
            }
          }

          p {
            font-size: 1rem;
          }

          h1,
          h2 {
            font-size: 2rem;

            display: block;
          }
        }

        .quiz__skala {
          height: auto;
        }
      }
    }
  }
}

@media (min-width: $lg) {
  .quiz {
    grid-column: 1;
    grid-row: span 2;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .quiz__content_wrapper {
      position: absolute;
      display: flex;
      // flex-direction: column;
      // justify-content: flex-start;

      .quiz__content {
        padding: 0 0 0 10%;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: auto auto;
        // padding: 10%;
        height: auto;
        width: auto;

        .quiz__text {
          display: flex;
          flex-direction: column;
          padding-right: 1rem;
          width: 320px;
          margin-bottom: 0;
          line-break: auto;

          .quiz__back {
            margin-top: auto;
            cursor: pointer;
            display: flex;
            align-items: center;

            p {
              margin-left: 1rem;
            }
          }

          p {
            font-size: 1rem;
          }

          h1,
          h2 {
            font-size: 2.5rem;

            display: block;
          }
        }

        .quiz__skala {
          height: auto;
        }
      }
    }
  }
}

@media (min-width: $xl) {
  .quiz {
    .quiz__content_wrapper {
      .quiz__content {
        .quiz__text {
          width: auto;

          p {
            font-size: 1.2rem;
          }
        }
      }

      .quiz__skala {
      }
    }
  }
}
@media (max-height: $sm) {
  .quiz {
    height: 90vh;
    margin: 40px 0;

    align-items: flex-start;
  }
}
@media (max-height: $md) {
  .quiz {
    height: 100%;
  }
}

.image {
  display: flex;
  justify-content: center;
  width: auto;
  grid-row: 2;
  margin: 0 2rem;

  img {
    width: 100%;
    min-height: auto;
    max-width: 540px;
    align-self: flex-start;
  }
}
@media (min-width: $md) {
  .image {
    grid-column: 1;
    grid-row: 1;
    display: flex;

    margin: 0 0;

    flex-flow: row nowrap;
    align-items: center;
    // width: 100%;
    // height: 100%;
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: 50%;

    img {
      width: 120%;
      transform: translateX(-2%);
      align-self: auto;
    }
  }
}

@media (min-width: $lg) {
  .image {
    grid-column: 2;
    grid-row: 1;
    display: flex;

    flex-flow: row nowrap;
    align-items: center;
    // width: 100%;
    // height: 100%;
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: 50%;

    img {
      max-width: none;

      width: 100%;
      transform: scale(100%) translateX(0);
    }
  }
}

@media (min-width: $xl) {
  .image {
    img {
      transform: translateX(5%);
    }
  }
}

@media (min-width: $xxxl) {
  .image {
    img {
      transform: scale(110%);
    }
  }
}

@mixin color($color) {
  svg {
    fill: $color !important;
  }
  h2 {
    color: $color;
  }
  .quiz__back p {
    color: $color;
  }
}

.quiz--blue {
  @include color($c-blue);
}

.quiz--red {
  @include color($c-red);
}

.quiz--orange {
  @include color($c-orange);
}

.quiz--green {
  @include color($c-green);
}

.quiz--yellow {
  @include color($c-yellow);
}

.quiz--purple {
  @include color($c-purple);
}
</style>
