<template>
  <div id="app">
    <span v-if="!impressumView">
      <Code v-if="codeError" :content="codeContent" />

      <Intro
        v-else-if="introContent && slide == -1"
        :content="introContent"
        @incrementSlide="incrementSlide()"
      />
      <Test
        v-else-if="slide >= 0 && slide < questions.length"
        :activeQuestion="questions[slide]"
        @nextSlide="nextSlide($event)"
        @previousSlide="slide--"
      />
      <Result v-else-if="slide == questions.length + 1" :content="outroContent" />
      <Loader v-else />
    </span>
    <Impressum v-else :content="impressumContent" @toggle="impressumView = !impressumView" />
    <Footer :show="footerItems" @toggle="impressumView = !impressumView" />
  </div>
</template>

<script>
import axios from 'axios';
import Code from '@/components/Code.vue';
import Intro from '@/components/Intro.vue';
import Test from '@/components/Test.vue';
import Result from '@/components/Result.vue';
import Loader from '@/components/Loader.vue';
import Footer from '@/components/Footer.vue';
import Impressum from '@/components/Impressum.vue';

export default {
  name: 'App',
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,

      loading: true,
      code: '',
      codeError: false,
      codeContent:
        '# Willkommen zum Online-Tool "Stärkeneinschätzung" \n\nDas Tool ist Teil des Projektes "Stärkenpriming" und ermöglicht eine digitale Stärkeneinschätzung zur Vorbereitung einer Kurzintervention im Beratungskontext. Es basiert auf den 24 Charakterstärken nach Peterson und Seligman. \n\nUm die digitale Stärkeneinschätzung durchzuführen, ist ein Zugangscode notwendig. Wenn Sie einen Code und weitere Informationen zum Stärkenpriming erhalten möchten, kontaktieren Sie uns per Mail: [staerken@ajb.zh.ch](mailto:staerken@ajb.zh.ch)',
      slide: -1,
      impressumView: false,
      impressumContent: '',
      introContent: '',
      outroContent: '',
      questions: [],
      impressum: '',
      results: [],
    };
  },
  components: {
    Code,
    Intro,
    Test,
    Result,
    Loader,
    Footer,
    Impressum,
  },
  methods: {
    /*    async fetchQuiz(token) {*/
    async fetchQuiz() {
      // let code = window.location.href.split("/");
      const code = new URLSearchParams(window.location.search).get('code');

      if (code) {
        await axios
          .get(this.apiUrl + '/clients/' + code, {})
          .then(response => {
            if (response.data.error) {
              let customError = new Error(response.data.error);
              customError.response = response.data.error;
              return Promise.reject(customError);
            } else {
              this.introContent = response.data.intro;
              this.outroContent = response.data.outro;
              this.impressum = response.data.Impressum;
              this.questions = response.data.questions;
              this.code = code;
            }
          })
          .catch(error => {
            // Handle error.
            console.warn('An error occurred in quiz:', error.response);
            if (error.response == 'already answered') {
              this.codeContent =
                'Scheint so, als wäre Ihr Code ungültig oder schon benutzt worden.\n\nFalls Sie Probleme mit der Durchführung der Stärkeneinschätzung haben, kontaktieren Sie uns per Mail: [staerken@ajb.zh.ch](mailto:staerken@ajb.zh.ch) ';
            } else if (error.response == 'invalid code') {
              this.codeContent =
                'Scheint so, als wäre Ihr Code ungültig oder schon benutzt worden.\n\nFalls Sie Probleme mit der Durchführung der Stärkeneinschätzung haben, kontaktieren Sie uns per Mail: [staerken@ajb.zh.ch](mailto:staerken@ajb.zh.ch) ';
            } else {
              this.codeContent =
                'Tut uns leid. Es scheint ein Fehler aufgetreten zu sein.\n\nFalls Sie Probleme mit der Durchführung der Stärkeneinschätzung haben, kontaktieren Sie uns per Mail: [staerken@ajb.zh.ch](mailto:staerken@ajb.zh.ch) ';
            }
            this.codeError = true;
          });
      } else {
        this.codeError = true;
      }
    },
    async fetchImpressum() {
      await axios
        .get(this.apiUrl + '/impressum/')
        .then(response => {
          this.impressumContent = response.data.data.attributes.content;
        })
        .catch(error => {
          console.log('An error occurred in impressum:', error.response);
        });
    },
    incrementSlide() {
      this.slide++;
      scroll(0, 0);
    },
    nextSlide(x) {
      if (this.slide < this.questions.length) {
        this.questions[this.slide].answer = Number(x);
        this.incrementSlide();
      }
      if (this.slide == this.questions.length) {
        this.results = this.questions
          // .filter((q) => q.answer > 1)
          .sort((a, b) => b.answer - a.answer);
        // .splice(0, 5);

        /* send result */
        // let code_arr = window.location.href.split("/");
        // let code = code_arr[code_arr.length - 1];
        const code = new URLSearchParams(window.location.search).get('code');
        axios
          .post(
            this.apiUrl + '/emails',
            {
              code: code,
              answers: JSON.stringify(this.results),
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(res => {
            console.log('good');
            console.log(res);
            //Perform Success Action
          })
          .catch(error => {
            console.error(error);
            this.outroContent =
              'Huch. Es scheint ein Fehler aufgetreten zu sein. Bitte versuche es später nocheinmal.';
            // error.response.status Check status code
          })
          .finally(() => {
            this.incrementSlide();
            //Perform action in always
          });
      }
    },
  },
  mounted() {
    this.fetchImpressum();
    this.fetchQuiz();
  },
  computed: {
    // activeQuestion: function () {
    //   return this.slide >= 0 ? this.questions[this.slide] : this.questions[0];
    // },
    footerItems: function() {
      return {
        logo: this.slide < 0 || this.slide >= this.questions.length || this.impressumView,
        // copyright: this.slide < 0 || this.impressumView,
        copywright: false,
        impressum: !this.impressumView,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/look.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}
html {
  font-size: 16px;
  ::selection {
    background: $white;
    color: $blue;
  }
}

body {
  background-color: $blue;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

h1,
h2,
.h2 p,
h3 {
  font-family: bree-serif, serif;
  font-weight: 600;
  // font-style: normal;
  color: $white;
  line-height: 1.2;
  word-break: break-word;
}
h1 {
  font-size: 1.8rem;
  word-break: break-word;

  @media (min-width: $md) {
    word-break: normal;
    font-size: 2.6rem;
  }

  @media (min-width: $xl) {
    font-size: 3.2rem;
  }
}
h2,
.h2 p {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

p,
button,
a,
input {
  font-family: erbaum, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: $white;

  font-size: 1rem;
  // @media (min-width: $md) {
  //   font-size: 1.2rem;
  // }
  line-height: 1.7;
}
input {
  color: $blue;
  text-align: center;
}

.btn {
  border: 3px solid $white;
  border-radius: 100px;
  padding: 0.7rem 3rem;
  font-weight: 500;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.image-enter-active,
.image-leave-active {
  transition: all 0.3s ease-out;
}
.image-enter {
  transform: translate(-10%, -100%) !important;
}
.image-leave-to {
  transform: translate(-10%, 100%) !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
