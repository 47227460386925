<template>
  <section class="intro">
    <div class="intro__grid">
      <div class="intro__icon">
        <img
          src="../assets/icons/outro-swirl.svg"
          alt=""
          style="margin-top: 10vh"
        />
      </div>
      <div class="intro__content">
        <vue-markdown class="intro__text">{{ content }}</vue-markdown>
      </div>
      <div class="intro__icon">
        <img
          src="../assets/icons/outro-wave.svg"
          alt=""
          style="margin-top: 80vh"
        />
      </div>
    </div>
    <!-- <Footer /> -->
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "Result",
  components: {
    VueMarkdown,
  },
  props: {
    content: String,
  },
};
</script>

<style lang="scss">
.result {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>