<template>
  <section class="intro">
    <div class="intro__grid">
      <div class="intro__icon">
        <img
          src="../assets/icons/intro-wave.svg"
          alt=""
          style="margin-top: 60vh"
        />
      </div>
      <div class="intro__content">
        <vue-markdown class="intro__text">{{ content }}</vue-markdown>
        <!-- <input type="input" v-model="code" />
        <br />
        <button class="btn" @click="checkCode()">Los geht's</button> -->
      </div>
      <div class="intro__icon">
        <img
          src="../assets/icons/intro-zickzack.svg"
          alt=""
          style="margin-top: 10vh"
        />
      </div>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "Code",
  props: {
    content: String
  },
  components: {
    VueMarkdown
  },
  methods: {
    checkCode() {
      window.location.href = window.location.href + this.code;
    }
  }
};
</script>

<style lang="scss" scoped>
input {
  max-width: 500px;
  width: 100%;
  height: 50px;
  margin-bottom: 1em;
}
</style>
