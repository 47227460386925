<template>
  <div class="skala-wrapper">
    <div class="skala">
      <div
        class="skala__question"
        :class="{ 'skala__question--active': active === 9 }"
        :value="8"
        @click="$emit('clicked', 9)"
      >
        völlig zutreffend
      </div>
      <div
        class="skala__question"
        :class="{ 'skala__question--active': active === 8 }"
        :value="7"
        @click="$emit('clicked', 8)"
      >
        zutreffend
      </div>
      <div
        class="skala__question"
        :class="{ 'skala__question--active': active === 7 }"
        :value="6"
        @click="$emit('clicked', 7)"
      >
        ziemlich zutreffend
      </div>

      <div
        class="skala__question"
        :class="{ 'skala__question--active': active === 6 }"
        :value="5"
        @click="$emit('clicked', 6)"
      >
        eher zutreffend
      </div>
      <div
        class="skala__question"
        :class="{ 'skala__question--active': active === 5 }"
        :value="4"
        @click="$emit('clicked', 5)"
      >
        weder noch
      </div>
      <div
        class="skala__question"
        :class="{ 'skala__question--active': active === 4 }"
        :value="3"
        @click="$emit('clicked', 4)"
      >
        eher unzutreffend
      </div>
      <div
        class="skala__question"
        :class="{ 'skala__question--active': active === 3 }"
        @click="$emit('clicked', 3)"
      >
        ziemlich unzutreffend
      </div>
      <div
        class="skala__question"
        :class="{ 'skala__question--active': active === 2 }"
        @click="$emit('clicked', 2)"
      >
        unzutreffend
      </div>
      <div
        class="skala__question"
        :class="{ 'skala__question--active': active === 1 }"
        @click="$emit('clicked', 1)"
      >
        völlig unzutreffend
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Skala",
  data() {
    return {};
  },
  props: {
    active: Number
  }
};
</script>

<style scoped lang="scss">
@import "../scss/look.scss";

.skala-wrapper {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .skala {
    width: 100%;
    max-width: 400px;

    counter-reset: listCounter 10;

    .skala__question {
      font-family: erbaum, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 1rem;
      display: flex;
      align-items: center;
      color: $blue;
      padding: 10px;
      background-color: $white;
      border-radius: 100px;
      cursor: pointer;

      @media (max-height: $md) {
        padding: 4px 10px;
      }

      &.skala__question--active {
        background-color: #b5d0f3;
      }

      &:not(:first-child) {
        margin-top: 0.8rem;
      }
      transform: translateX(0px);
      transition: 0.3s;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: #e6f1ff;
          transform: translateX(5px);
        }
      }

      counter-increment: listCounter -1;

      &::before {
        content: counter(listCounter);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        font-size: 0.9rem;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background-color: $blue;
        color: $white;
        transition: 0.5s;
        margin-top: 0.5px;
      }
    }
  }
}

@media (min-width: $md) {
  .skala {
    .skala__question {
      width: 260px;
    }
  }
}
</style>