<template>
  <div class="footer">
    <img
      v-if="show.logo"
      src="../assets/icons/AJB.svg"
      alt="AJB Logo"
      class="footer__ajb"
    />
    <p v-if="show.copyright" class="footer__copyright">
      Character Strengths Rating Form (Texte: VIA-Youth) <br />
      &copy; W. Ruch und Team, Psychologisches Institut der Universität Zürich
      <br />
      Bildmaterial zugefügt
    </p>
    <p v-if="show.impressum" @click="$emit('toggle')" class="footer__impressum">
      Impressum
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    show: Object
  }
};
</script>

<style lang="scss">
@import "../scss/look.scss";

.footer {
  padding: 2rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .footer__ajb {
    width: 220px;
    order: 3;
  }
  .footer__copyright {
    font-size: 80%;
    text-align: center;
    order: 2;
    margin-bottom: 20px;
  }
  .footer__impressum {
    cursor: pointer;
    font-size: 80%;
    order: 1;
    margin-bottom: 20px;
  }
}

@media (min-width: $md) {
  .footer {
    min-height: 10vh;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 0 2rem;
    margin-top: 0;
    .footer__ajb {
      grid-row: 1;
      grid-column: 1;
    }
    .footer__copyright {
      grid-row: 1;
      grid-column: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }
    .footer__impressum {
      grid-row: 1;
      grid-column: 3;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }
}
</style>