<template>
  <div class="impressum">
    <div class="impressum__close">
      <img
        src="../assets/icons/close.svg"
        alt="schliessen"
        @click="$emit('toggle')"
      />
    </div>
    <div class="impressum__content">
      <vue-markdown>{{ content }}</vue-markdown>
    </div>
    <!-- <div class="footer">
        <p @click="$emit('toggle')" class="footer__impressum">Schliessen</p>
      </div> -->
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "Impressum",
  props: {
    content: String,
  },
  components: {
    VueMarkdown,
  },
};
</script>

<style lang="scss">
.impressum {
  top: 0;
  left: 0;
  width: 100vw;

  .impressum__close {
    position: absolute;
    display: flex;
    width: 100vw;
    height: 10vh;
    justify-content: flex-end;
    align-items: flex-start;
    img {
      width: 3rem;
      margin: 3rem;
      cursor: pointer;
    }
  }
  .impressum__content {
    height: 90vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  p {
    margin-bottom: 2rem;
  }
}
</style>